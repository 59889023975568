import React from 'react';

import { CFColorTokenProp } from '../../../theme/tokens/contentful-color';

import { SharedProps, WidgetProps } from '../shared';

import { Text } from '../../Text';
import { ButtonColorTokenProp } from '~/theme/tokens/button-color';

export const colorClasses = {
  sweetmint: {
    bg: 'bg-navy',
    text: 'text-white',
    hoverBg: 'hover:bg-navy',
    hoverText: 'hover:text-white'
  },
  spearmint: {
    bg: 'bg-spearmint',
    text: '',
    hoverBg: 'hover:bg-spearmint',
    hoverText: ''
  },
  cinnamint: {
    bg: 'bg-cinnamint',
    text: '',
    hoverBg: 'hover:bg-cinnamint',
    hoverText: ''
  },
  blackberry: {
    bg: 'bg-blackberry',
    text: 'text-white',
    hoverBg: 'hover:bg-black',
    hoverText: 'hover:text-white'
  },
  white: { bg: 'bg-white', text: '', hoverBg: 'hover:bg-white', hoverText: '' },
  ginger: {
    bg: 'bg-ginger',
    text: '',
    hoverBg: 'hover:bg-ginger',
    hoverText: ''
  },
  green: { bg: 'bg-green', text: '', hoverBg: 'hover:bg-green', hoverText: '' },
  limegreen: {
    bg: 'bg-limegreen',
    text: '',
    hoverBg: 'hover:bg-limegreen',
    hoverText: ''
  }
};

type ValidColorKeys = keyof typeof colorClasses;

function getButtonClasses(
  color: ButtonColorTokenProp | CFColorTokenProp | string
) {
  if (typeof color === 'string' && color in colorClasses) {
    return colorClasses[color as ValidColorKeys];
  }

  return { bg: '', text: '', hoverBg: '', hoverText: '' };
}

export function RoleSelectWebregWidget({
  buttonText = `I'm a parent or guardian`,
  buttonColor = 'sweetmint',
  buttonHoverColor = 'green',
  secondaryButtonText = `I'm a kid or teen`,
  secondaryButtonColor = 'ginger',
  secondaryButtonHoverColor = 'green',
  label,
  inputProps
}: WidgetProps & SharedProps) {
  const buttonClasses = getButtonClasses(buttonColor);
  const buttonHoverClasses = getButtonClasses(buttonHoverColor);

  const secondaryButtonClasses = getButtonClasses(secondaryButtonColor);
  const secondaryButtonHoverClasses = getButtonClasses(
    secondaryButtonHoverColor
  );

  return (
    <div className="inline-flex flex-col items-start justify-start gap-2 px-1 pb-6 tablet:gap-4 tablet:p-0">
      {label && (
        <div className="mt-1 self-stretch text-nowrap text-base font-medium leading-normal text-white tablet:text-xl tablet:leading-[26px]">
          {label}
        </div>
      )}
      <div className="flex w-full flex-1 flex-row flex-wrap items-center justify-normal gap-4 tablet:items-start tablet:justify-center">
        <button
          className={`primary-button ${buttonClasses.bg} ${buttonClasses.text} ${buttonHoverClasses.hoverBg} ${buttonHoverClasses.hoverText}`}
          type="submit"
          name="role"
          value="parent"
          aria-label={buttonText as string}
          data-testid="parent-btn"
          onClick={() => inputProps!.handleOnChange!('parent')}
        >
          <Text fontSize="s">{buttonText}</Text>
        </button>
        <button
          className={`primary-button ${secondaryButtonClasses.bg} ${secondaryButtonClasses.text} ${secondaryButtonHoverClasses.hoverBg} ${secondaryButtonHoverClasses.hoverText}`}
          type="submit"
          name="role"
          value="child"
          aria-label={secondaryButtonText as string}
          data-testid="child-btn"
          onClick={() => inputProps!.handleOnChange!('child')}
        >
          <Text fontSize="s">{secondaryButtonText}</Text>
        </button>
      </div>
    </div>
  );
}
